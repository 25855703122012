.TermsAndConditions {
  width: 100%;
  min-height: 800px;
  padding-top: 100px;
  // background: url("../../images/showcase.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9;
  }
  .container {
    padding: 30px 20px;
    position: inherit;
    z-index: 10;
    .content {
      width: 100%;
      padding: 30px 20px;
      height: 60% !important;
      background: #fff;
      padding: 20px;
      &__title {
        font-size: 40px;
        color: var(--main-color);
        line-height: 32px;
      }
      &__descriptions {
        &__description {
          margin: 10px 0;
          span {
            color: var(--main-color);
            font-weight: 700;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .TermsAndConditions {
    .container {
      .content {
        &__title {
          font-size: 25px;
          color: var(--main-color);
        }
        &__descriptions {
          &__description {
            margin: 10px 0;
            font-size: 15px !important;
            span {
              color: var(--main-color);
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .TermsAndConditions {
    .container {
      width: 100% !important;
      .content {
        &__title {
          font-size: 20px;
          color: var(--main-color);
        }
        &__descriptions {
          &__description {
            margin: 10px 0;
            font-size: 13px !important;
            span {
              color: var(--main-color);
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}
